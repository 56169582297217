<template>
    <ViewTitle :title="`保單利益給付表 | ${this.productName ?? ''}`" />
    <Table :columnConfig="activeColumns" :rows="rows" :isUseCard="false" />
</template>

<script>
import _ from 'lodash'
import ViewTitle from '@/components/ViewTitle.vue'
import Table from '@/components/Table.vue'

export default {
    name: 'Benefit',
    components: {
        ViewTitle,
        Table
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    methods: {},
    computed: {
        activeColumns: function () {
            const column = _.cloneDeep(this.columnsKey)
            let columnsList = {}

            column?.forEach((col) => {
                const isInsYear = col?.key === 'insYear'
                let valueLength = col?.value.length ?? 0
                columnsList[col?.key] = {
                    label: col.value,
                    sortEnabled: false,
                    isFilterable: false,
                    type: 'number',
                    position: isInsYear ? 'center' : 'right',
                    width: 100,
                    widthPx:
                        valueLength > 8
                            ? valueLength * 16 + 10
                            : valueLength * 18 + 10,
                    isFixed: isInsYear ? true : false
                }
            })
            return columnsList
        },
        insNo: function () {
            return this.$route.params.insNo
        },
        id: function () {
            return this.$route.params.id
        },
        benefit: function () {
            const benefit = this.$store.state.supplierSyncBenefit
            if (benefit && benefit[this.insNo]) {
                return benefit[this.insNo][this.id]
            }
            return undefined
        },
        productName: function () {
            return this.benefit?.productName
        },
        columnsKey: function () {
            return this.benefit?.amountDetailKey
        },
        rows: function () {
            return this.benefit?.amountDetailList
        }
    },
    watch: {},
    data() {
        return {}
    },
    created() {
        if (!this.benefit) {
            this.$router.push({
                name: 'TiaInsurance'
            })
        } else {
            this.$showErrorMessage('提醒您，此網址為暫時連結，無法儲存與分享。')
        }
    }
}
</script>

<style lang="scss" scoped>
// :global(.outside-container > .container) {
//     margin: 20px auto;
//     max-width: unset;
// }

// :global(.outside-container > .container > .main-content) {
//     width: calc(100% - 30px) !important;
// }

.main-header-container {
    padding: 10px 20px;
    :deep(.main-header-word) {
        font-size: 20px;
    }
}

:deep(.table-container) {
    max-height: unset;
    overflow-y: unset;
    margin-bottom: 0;
    .table {
        max-height: unset;
        overflow-y: unset;
    }
}
</style>
